/**
 * Support Links - 3 Cases
 * 1. Storage File to be Downloaded:
 *  - By `fileName`
 * 2. TODO - Support Page & External contact button
 *  - By `url`
 * */
export const supportLinks = [
  {
    title: "How to Create Shipment",
    fileName: "Users/Knowledge Base/How To Create a Shipment 1.3.pdf",
    description:
      "Required fields and specification on how to create a Shipment",
  },
  {
    title: "How to Create Appointment",
    fileName: "Users/Knowledge Base/How To Create an Appointment.pdf",
    description:
      "Required fields and specification on how to create a Appointments, recurrences and calendar views.",
  },
  // {
  //   title: "How to Create Warehouse",
  //   fileName: "https://github.com.br/viniciuSquare.png",
  //   description:
  //     "Required fields and specification on how to create a Warehous, work hours, Docks details and maintenance schedules",
  // },
];
