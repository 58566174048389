export class FreightClassUtils {
    static calc(weight, weightUOM, length, width, height, unit) {
        const foot2Inch = (value) => value * 12;
        const cm2Inch = (value) => value / 2.54;
        const kg2lbs = (value) => value / 0.45359237;

        let actualWeight = weight;
        if (weightUOM === '(kg)') {
            actualWeight = kg2lbs(weight);
        }

        let actualLength = length;
        let actualWidth = width;
        let actualHeight = height;
        if (unit === 'Foot') {
            actualLength = foot2Inch(length);
            actualWidth = foot2Inch(width);
            actualHeight = foot2Inch(height);
        } else if (unit === 'Centimeters') {
            actualLength = cm2Inch(length);
            actualWidth = cm2Inch(width);
            actualHeight = cm2Inch(height);
        }

        actualLength = actualLength / 12;
        actualWidth = actualWidth / 12;
        actualHeight = actualHeight / 12;

        // Calculate volume in cubic feet
        const volume = actualLength * actualWidth * actualHeight;
        if (volume <= 0) throw new Error("Calculated volume must be greater than zero.");

        // Calculate density in pounds per cubic foot
        const density = actualWeight / volume;

        // Determine freight class based on density
        if (density >= 50) return 50;
        if (density >= 35) return 55;
        if (density >= 30) return 60;
        if (density >= 22.5) return 65;
        if (density >= 15) return 70;
        if (density >= 13) return 77.5;
        if (density >= 12) return 85;
        if (density >= 10.5) return 92.5;
        if (density >= 9) return 100;
        if (density >= 8) return 110;
        if (density >= 7) return 125;
        if (density >= 6) return 150;
        if (density >= 5) return 175;
        if (density >= 4) return 200;
        if (density >= 3) return 250;
        if (density >= 2) return 300;
        if (density >= 1) return 400;
        return 500; // Density < 1
    }
}