import { createContext, useEffect } from "react";
import { useLocationForm } from "../hooks/useLocationForm";
import { FormProvider } from "react-hook-form";
import { Box } from "@mui/material";

export const LocationFormContext = createContext(null);

export function LocationFormProvider({ children, ...props }) {
    const useFormParams = props.useFormParams;
    const onSave = props.onSave;
    const reset = props.reset || false;

    const {
        isLoading,
        form,
        handleSubmit,
        onSubmitHandler,
        setError,
        clearErrors,
        mapFields,
        fieldsContact,
        removeContact,
        appendContact,
        ...rest
    } = useLocationForm({ useFormParams, onSave, reset });

    useEffect(() => {
        if (useFormParams?.defaultValues) mapFields(useFormParams.defaultValues);
    }, [useFormParams]);

    return (
        <LocationFormContext.Provider
            value={{
                isLoading,
                form,
                handleSubmit,
                onSubmitHandler,
                setError,
                clearErrors,
                mapFields,
                fieldsContact,
                removeContact,
                appendContact,
                ...rest
            }}
        >
            <FormProvider {...form}>
                <Box
                    component="form"
                    autoComplete="off"
                    onSubmit={(event) => {
                        clearErrors();
                        handleSubmit(onSubmitHandler)(event);
                    }}
                    sx={props.sx}
                >
                    {children}
                </Box>
            </FormProvider>
        </LocationFormContext.Provider>
    );
};