import React from "react";
import { TextField, IconButton, MenuItem, Stack, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Close } from "@mui/icons-material";
import useContactUsForm from "./useContactUsForm";

export const ContactUs = (props) => {
  const {
    handleSubmit,
    emailSent,
    formErrors,
    apiFail,
    onSubmitForm,
    isLoading,
    issueTypeValidator,
    requisitionValidator,
    emailValidator,
    nameValidator,
  } = useContactUsForm();

  const [issue, setIssue] = React.useState(props.issues[0]);

  const onPressClose = () => {
    props.handleClose();
  };


  const handleChange = (event: SelectChangeEvent) => {
    setIssue(event.target.value);
  };


  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
     
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "8px",
          backgroundColor: "#f5f5f5",
          maxWidth: "600px",
          width: "100%",
          maxHeight: "95vh",
          overflow: "auto",
          padding: "30px"
        }}
      >
         <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={onPressClose} style={{ maxWidth: 32 }}>
            <Close />
          </IconButton>
        </div>
        

        <div style={{ marginTop: -30 }}>
          <h2>Support Request</h2>
          <p>
            For any inquiries or assistance, please fill out the form below and
            we will get back to you as soon as possible.
          </p>
        </div>


        <div>
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <Stack spacing={2}>
              {emailSent ? (
                <Alert severity="success" sx={{ mt: 1 }}>
                  Email sent, we're reaching out with you soon.
                </Alert>
              ) : (
                <>
                  <TextField
                    {...nameValidator}
                    name="name"
                    label="Name"
                    error={Boolean(formErrors.name)}
                    helperText={formErrors.name?.message}
                    fullWidth
                  />
                  <TextField
                    {...emailValidator}
                    name="email"
                    label="Email address"
                    error={Boolean(formErrors.email)}
                    helperText={formErrors.email?.message}
                    fullWidth
                  />
                  <TextField name="phone" label="Phone Number" fullWidth />
                  <TextField
                    {...issueTypeValidator}
                    name="issue"
                    error={Boolean(formErrors.issuetype?.message)}
                    label="Issue type"
                    select
                    fullWidth
                    default={props.issues[0]}
                    onChange={handleChange}
                    value={issueTypeValidator.value} // Add this line
                  >
                    {props.issues.map((option) => (
                      <MenuItem key={option.id} value={option}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    {...requisitionValidator}
                    name="requisition"
                    label="Describe your requisition"
                    multiline
                    rows={2}
                    error={Boolean(formErrors.requisition)}
                    helperText={formErrors.requisition?.message}
                    fullWidth
                  />
                  <LoadingButton
                    variant="contained"
                    fullWidth
                    type="submit"
                    loading={isLoading}
                    sx={{ py: "0.8rem", mt: "1rem" }}
                  >
                    Send request
                  </LoadingButton>
                </>
              )}
              {apiFail && (
                <Alert severity="error" sx={{ mt: 1 }}>
                  An error occurred, please again later.
                </Alert>
              )}
            </Stack>
          </form>
        </div>
      </div>
    </div>
  );
};
