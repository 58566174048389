import {
  Box,
  Button,
  Divider,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { capitalize } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormAccordion } from "src/components/form/FormAccordion";
import { FormInput } from "src/components/form/FormInput";
import { useAuth } from "src/hooks/useAuth";
import { useShipmentFormContext } from "src/pages/shipments/hooks/useForm";
import {
  dimensionsUnits,
  freightClasses,
  palletCountUnits,
  palletCoutLabel,
  weightUnits,
} from "../../units";
import { HazmatFieldsWrapper } from "../HazmatFields";
import { FreightClassUtils } from "src/utils/freight";


export function PackageForm({ packageIndex = 0, onUpdated, isEdditing }) {

  const { session } = useAuth();
  const isSupplierManager = session.permissions.IsBackOffice || session.permissions.IsSupplierManager;

  const { form } = useShipmentFormContext();
  const { watch, getValues, setValue } = useFormContext();

  const item = getValues(`items.${packageIndex}`);
  const InitialFormState = {
    isShowSpecification: item?.description ?? false,
    isShowAdditionalInfo: item?.actualQty ?? false,
    quantityLabel: item?.actualQtyUOM ? palletCoutLabel[palletCountUnits.findIndex(v => v === item.actualQtyUOM)] : palletCoutLabel[12],
  }
  const [state, setState] = useState(InitialFormState);

  useEffect(() => {
    const item = getValues(`items.${packageIndex}`);
    setState({
      ...state,
      isShowSpecification: item?.description ?? false,
      isShowAdditionalInfo: item?.actualQty ?? false,
      quantityLabel: item?.actualQtyUOM ? palletCoutLabel[palletCountUnits.findIndex(v => v === item.actualQtyUOM)] : palletCoutLabel[12],
    });
  }, [
    watch(`items.${packageIndex}.description`),
    watch(`items.${packageIndex}.actualQty`),
    watch(`items.${packageIndex}.actualQtyUOM`)
  ])

  const calcFreightClass = () => {
    const item = getValues(`items.${packageIndex}`);
    const isSpecificationSpecified = item?.actualLength && item?.actualWidth && item?.actualHeight && item?.actualWeight;
    if (isSpecificationSpecified) {
      const freightClass = FreightClassUtils.calc(item.actualWeight, item.actualWeightUOM, item.actualLength, item.actualWidth, item.actualHeight, item.actualDimensionsUOM);
      console.log(`${freightClass} class value`);
      setValue(`items.${packageIndex}.class`, freightClass);
    }
  }

  useEffect(() => { calcFreightClass() }, [
    watch(`items.${packageIndex}.actualDimensionsUOM`),
    watch(`items.${packageIndex}.actualLength`),
    watch(`items.${packageIndex}.actualWidth`),
    watch(`items.${packageIndex}.actualHeight`),
    watch(`items.${packageIndex}.actualWeight`),
    watch(`items.${packageIndex}.actualWeightUOM`),
  ]);



  return (
    <Box sx={{
      display: "flex",
      flexDirection: 'column',
      width: '100%',
      borderRadius: '32'
    }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          mb: '2rem'
        }}
      >
        <Typography
          fontWeight={600}
          variant="h1"
          sx={{ "&.MuiTypography-h1": { fontSize: "1.5rem" } }}
        >
          {isEdditing ? 'Update package' : 'Add package'}
        </Typography>
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        mb: '1rem'
      }}>
        <Typography variant="caption" fontSize={"1rem"}>
          Basic Info
        </Typography>
        <Stack
          spacing={1}
          direction={{
            xs: "column",
            md: "row",
          }}
        >
          <FormInput
            label="Package ID"
            name={`items.${packageIndex}.itemId`}
            type="text"
            fullWidth
          />
          <FormInput
            name={`items.${packageIndex}.actualValue`}
            label="Value"
            type="number"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
          {isSupplierManager && <><FormInput
            name={`items.${packageIndex}.palletNumber`}
            label={`${state.quantityLabel} Id Number`}
            type="number"
            fullWidth
          />
            <FormInput
              name={`items.${packageIndex}.poNumber`}
              label="PO Number"
              type="text"
              select
              fullWidth
            >
              {form.watch("poNumber") &&
                Array.isArray(form.watch("poNumber")) ? (
                form.watch("poNumber").map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))
              ) : (
                <MenuItem
                  key={form.watch("poNumber")}
                  value={form.watch("poNumber")}
                >
                  {form.watch("poNumber")}
                </MenuItem>
              )}
            </FormInput></>}
        </Stack>
        <Controller
          control={form.control}
          name={`items.${packageIndex}.description`}
          render={({ field }) => (
            <TextField
              label="Description"
              {...field}
              fullWidth
              required
            />
          )}
        />
      </Box>

      {state.isShowSpecification && <>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            my: '1rem'
          }}
        >
          {/* DIMENSIONS */}
          <Typography variant="caption" fontSize={"1rem"}>
            Specification
          </Typography>
          <Stack
            spacing={1}
            direction={{
              xs: "column",
              md: "row",
            }}
          >
            <FormInput
              name={`items.${packageIndex}.actualLength`}
              label="Length"
              type="number"
              min={0}
              onWheel={(event) => event.target.blur()}
              required
              fullWidth
            />
            <FormInput
              name={`items.${packageIndex}.actualWidth`}
              label="Width"
              type="number"
              min={0}
              onWheel={(event) => event.target.blur()}
              required
              fullWidth
            />
            <FormInput
              name={`items.${packageIndex}.actualHeight`}
              label="Height"
              type="number"
              min={0}
              onWheel={(event) => event.target.blur()}
              required
              fullWidth
            />
            <Box>
              <FormInput
                name={`items.${packageIndex}.actualDimensionsUOM`} // TODO - CHECK WITH SCHEMA
                label="Unit"
                defaultValue={
                  form.getValues("dimensionUOM")
                    ? form.getValues("dimensionUOM")
                    : dimensionsUnits[0].label
                }
                select
                required
                SelectProps={{
                  MenuProps: {
                    PaperProps: { sx: { maxHeight: "10rem" } },
                  },
                }}
                sx={{ width: "100%" }}
                fullWidth
              >
                {dimensionsUnits.map((option) => (
                  <MenuItem key={option.label} value={option.label}>
                    {`${option.abreviation} - ${option.label}`}
                  </MenuItem>
                ))}
              </FormInput>
            </Box>
          </Stack>

          {/* WEIGHT */}
          <Stack
            spacing={1}
            direction={{
              xs: "column",
              md: "row",
            }}
          >
            <FormInput
              name={`items.${packageIndex}.actualWeight`}
              label="Total Weight"
              type="number"
              required
              fullWidth
              onWheel={(event) => event.target.blur()}
              min={0}
            />
            <FormInput
              // sx={{ width: "45%" }}
              name={`items.${packageIndex}.actualWeightUOM`}
              label="Unit"
              defaultValue={
                form.getValues("weightUOM")
                  ? form.getValues("weightUOM")
                  : weightUnits[0].abreviation
              }
              select
              required
              fullWidth
            >
              {weightUnits.map((unit) => {
                return (
                  <MenuItem
                    key={unit.label}
                    value={unit.abreviation}
                  >{`${unit.abreviation} - ${unit.label}`}</MenuItem>
                );
              })}
            </FormInput>
          </Stack>

          {/* PIECE COUNT & PALLET COUNT */}
          <Stack
            spacing={1}
            direction={{
              xs: "column",
              md: "row",
            }}
          >
            <FormInput
              fullWidth
              name={`items.${packageIndex}.actualQty`}
              label={`${state.quantityLabel} Count`}
              type="number"
              required
              onWheel={(event) => event.target.blur()}
              min={0}
            />
            <FormInput
              name={`items.${packageIndex}.actualQtyUOM`}
              label="Unit"
              defaultValue={
                form.getValues("quantityUOM")
                  ? form.getValues("quantityUOM")
                  : palletCountUnits[12]
              }
              select
              required
              SelectProps={{
                MenuProps: {
                  PaperProps: { sx: { maxHeight: "10rem" } },
                },
              }}
              fullWidth
            >
              {palletCountUnits.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </FormInput>
          </Stack>
        </Box>
      </>
      }

      {state.isShowAdditionalInfo && <>
        <Divider />

        {/* FREIGHT CLASS & NMFC CODE & MANUFACTER PART NUMBER */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            my: '1rem'
          }}
        >
          <Typography variant="caption" fontSize={"1rem"}>
            Addional Information
          </Typography>
          <Stack
            spacing={2}
            direction={{
              xs: "column",
              md: "row",
            }}
          >
            <FormInput
              fullWidth
              select
              name={`items.${packageIndex}.class`}
              label="Freight Class"
              required={form.watch("mode") == "LTL" ? true : false}
              SelectProps={{
                MenuProps: {
                  PaperProps: { sx: { maxHeight: "10rem" } },
                },
              }}
            >
              {freightClasses
                .sort((a, b) => parseFloat(a) - parseFloat(b))
                .map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </FormInput>
            <FormInput
              fullWidth
              name={`items.${packageIndex}.nmfcCode`}
              label="NMFC Code"
              type="text"
            />
            <FormInput
              fullWidth
              name={`items.${packageIndex}.manufacturerPartNumber`}
              label="Manufacter Part Number"
              type="text"
            />
          </Stack>
        </Box>
      </>}

      <HazmatFieldsWrapper packageIndex={packageIndex} />
      <Box>
        <Button
          variant="contained"
          type="button"
          onClick={() => onUpdated(packageIndex)}
          fullWidth
          sx={{ minWidth: "8rem", height: "2.5rem", my: '2rem' }}
        >
          Save
        </Button>
      </Box>
    </Box >
  );
}