import {
  Location
} from "./location";

const cityStateZipREGEX = /^(.*),\s*([A-Z]{2})\s*([\d-]+)$/;

export class Order {
  static responseToShipment(orderResponse, locations) {
    const [, city, state, zip] = orderResponse.CITY_ST_ZIP.match(cityStateZipREGEX);

    function getStop() {
      if (orderResponse.stop) {
        return Location.toShipment(orderResponse.stop, "delivery");
      } else {
        return {
          type: 'delivery',
          name: orderResponse.SHIP_TO_LOCATION ?? "",
          address1: orderResponse.ADDRESS1 ?? "",
          address2: orderResponse.ADDRESS2 ?? "",
          city: city,
          state: state,
          zip: zip,
        };
      }
    }

    function getPickup() {
      if (orderResponse.pickup) {
        return Location.toShipment(orderResponse.pickup, "pickup");
      } else {
        return {
          type: 'pickup',
          name: orderResponse.VENDOR_NM ?? "",
        };
      }
    }

    return {
      shipmentNumber: [orderResponse.SHIPMENT_NUM ?? ""],
      items: [{
        itemId: orderResponse.ITEM_NUM ?? "",
        description: orderResponse.ITEM_DESC ?? "",
        poNumber: `${orderResponse.PO_NUM}-${orderResponse.RELEASE_NUM ?? 0}`
      }],
      locations: [
        ...locations,
        getPickup(),
        getStop(),
      ],
    };
  }
}