import {
  FileDownload,
  RemoveRedEye
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Stack,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { ContactButton } from "src/components/contact-button";
import { supportLinks } from "./constants/support-links";
import { useStorageQueries } from "./hooks/useQueries";

export function SupportPage() {
  return (
    <Container sx={{ height: "80vh" }} maxWidth={"md"}>
      {/* ADD HELMET */}
      <Box
        sx={{
          width: "100%",
          height: "5rem",
          display: "flex",
          alignItems: "flex-end",
          px: 2,
        }}
      >
        <h1>Help Center</h1>
      </Box>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Stack gap={2}>
          {supportLinks.map((support) => {
            return <SupportCard details={support} key={support.title} />;
          })}
        </Stack>
        <Card>
          <CardHeader
            title="Still need help? Contact Us"
            // subheader="We are here for you!"
          ></CardHeader>
          <CardContent>
            <ContactButton sx={{ width: "100%" }} />
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}

function SupportCard({ details }) {
  const [isDownloadActive, setIsDownloadActive] = useState(false);

  const { downloadFileFromStorage, isLoadingFile } = useStorageQueries();

  function handleFileDownload(fileName) {
    downloadFileFromStorage({ fileName, action: "download" });
    setIsDownloadActive(true);
  }

  function handleOpenFile(fileName) {
    downloadFileFromStorage({ fileName, action: "open" });
  }

  return (
    <Card
      sx={{
        p: 2,
      }}
      key={details.title}
    >
      <CardHeader
        title={details.title}
        subheader={details.description}
        action={
          <>
            <Tooltip title="Open File">
              <LoadingButton
                sx={{ minWidth: "fit-content" }}
                variant="text"
                onClick={() => handleOpenFile(details.fileName)}
                loading={!isDownloadActive && isLoadingFile}
              >
                <RemoveRedEye />
              </LoadingButton>
            </Tooltip>
            <Tooltip title="Download File">
              <LoadingButton
                sx={{ minWidth: "fit-content" }}
                variant="text"
                onClick={() => handleFileDownload(details.fileName)}
                loading={isDownloadActive && isLoadingFile}
              >
                <FileDownload />
              </LoadingButton>
            </Tooltip>
          </>
        }
        sx={{
          "&.MuiCardHeader-root": {
            p: 0,
          },
        }}
      ></CardHeader>
    </Card>
  );
}
