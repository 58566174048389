import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Modal,
  Paper,
  Tab,
  Typography,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  DescriptionOutlined,
  ListAltOutlined,
  RequestQuoteOutlined,
  LocalShippingOutlined,
  TimelapseRounded,
} from "@mui/icons-material";

// import { WareshouseBasicInfo } from "./BasicInfo";
// import { LocationsDetails } from "./LocationsDetails";

// import {
//   WareshouseDetailsProvider,
//   useWareshouseDetails,
// } from "../../context/WareshouseDetailsContext";
// import { DeleteWareshouseButton } from "../DeleteWareshouseButton";
import { useAuth } from "src/hooks/useAuth";
import { useNavigate } from "react-router-dom";

const tabs = [
  {
    title: "Basic Info",
    icon: <DescriptionOutlined />,
  },
  {
    title: "Docks",
    icon: <ListAltOutlined />,
  },
  {
    title: "Operation Hours",
    icon: <TimelapseRounded />,
  },
  {
    title: "Contacts",
    icon: <TimelapseRounded />,
  },
];

export function DetailsDrawer({
  children,
  title = "Details",
  selectedItem,
  onVisibilityChanged,
  onEditTapped,
  rootRoute,
  customHeaderActions,
}) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (isVisible) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsDrawerOpen(isVisible);
    onVisibilityChanged(isVisible);

    navigate(`/${rootRoute}`); 
  };

  useEffect(() => {
    setIsDrawerOpen(!!selectedItem);
  }, [selectedItem]);

  const Comp = true ? Drawer : Modal;

  return (
    <Paper>
      <Comp
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: {
              xs: '100vw',
              md: '75vw',
              lg: '50vw',
              xl: '800px'
            },
            transition: 'all .4s'
          }
        }}
        sx={{
          maxWidth: '100vw',
          transition: 'all .4s'
        }}
      >
        <Box
          role="presentation"
          sx={{
            py: "1.5rem",
          }}
        >
          {/* DRAWER HEADER */}
          <DrawerHeader
            onEditTapped={() => onEditTapped(selectedItem)}
            title={title}
            closeDrawer={toggleDrawer(false)}
            HeaderActions={customHeaderActions}
          />
          <Divider />
          <Box>
            {children}
          </Box>
        </Box>
      </Comp>
    </Paper>
  );
}

function DrawerHeader({ onEditTapped, title, closeDrawer, HeaderActions }) {
  return (
    <Box
      display={'grid'}
      gridTemplateAreas={{
        xs: `
          "title title close"
          "actions actions actions"
        `,
        md: `
          "title actions close"
        `
      }}
      gridAutoRows={{
        xs: 'auto auto',
        md: 'auto'
      }}
      alignItems="center"
      sx={{
        px: "1rem",
        mb: "1rem",
      }}
    >
      <Box gridArea={'title'} display={'flex'} alignItems={'center'}>
        <IconButton onClick={onEditTapped} sx={{ p: 2 }}>
          <EditIcon />
        </IconButton>
        <Typography variant="h6">{title}</Typography>
      </Box>

      <Box display="flex" alignItems="center" gridArea={'actions'}>
        {HeaderActions}
      </Box>
      <Box justifySelf={'end'} gridArea={'close'}>
        <IconButton onClick={closeDrawer} >
          <ChevronRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export function valueGetter(value, caseEmptyValue = "--") {
  return value ? value : caseEmptyValue;
}