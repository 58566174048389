import { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "src/hooks/useAuth";
import { contactUs } from "src/api/support.service";

function useContactUsForm(props) {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [apiFail, setApiFail] = useState(false);

  const { session } = useAuth();
  const onSubmitForm = async (data) => {
    setIsLoading(true);

    try {
     await contactUs(data, session.token);
     setEmailSent(true);
    } catch (error) {
      setApiFail(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleSubmit,
    apiFail,
    emailSent,
    formErrors,
    onSubmitForm,
    isLoading,
    nameValidator: {
      ...register("name", {
        required: "Name is required",
      }),
    },
    issueTypeValidator: {
      ...register("issue", {
        required: "Please, select a issue type",
      }),
    },
    emailValidator: {
      ...register("email", {
        required: "Email is required",
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: "Invalid format",
        },
      }),
    },
    requisitionValidator: {
      ...register("requisition", {
        required: "Requisition is required",
      }),
    },
  };
}

export default useContactUsForm;
