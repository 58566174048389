import { Paper } from "@mui/material";


import { Helmet } from "react-helmet-async";
import { ShipmentForm } from "./components/form/ShipmentForm";
import { useShipmentQueries } from "./hooks/useShipmentQueries";

export function CreateShipmentPage() {
  const { createShipment, isCreationLoading } = useShipmentQueries()

  return (
    <Paper
      sx={{
        maxWidth: {
          lg: "1000px",
          xs: "90%",
        },
        margin: "auto",
        py: 3,
      }}
      elevation={3}
    >
      <Helmet>
        <title>Create a New Shipment</title>
      </Helmet>
      <ShipmentForm
        onSave={createShipment}
        isSubmissionLoading={isCreationLoading}
      />
    </Paper>
  );
}
