import { DateTimePicker } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { Controller, useFormContext } from "react-hook-form";
import { LocationSearchField } from "./LocationSearchField/LocationsSearchField";
import { useEffect, useMemo, useState } from "react";

import { Typography, Stack, Box, Grid, MenuItem, Button, ListItem, Select, FormControl, InputLabel } from "@mui/material";

import { getDayjsDate } from "src/utils/formatTime";
import { shipmentServices } from "../../constants/services";
import { ServiceField } from "./ServicesFields";
import { LimitedAccessField } from "./LimitedAccessField";

import { defaultTimezone, timezones } from "src/utils/timezones";
import { useSnackbar } from "src/hooks/useSnackbar";
import { LocationListItem } from "./LocationListItem";

const { FormInput } = require("src/components/form/FormInput");

export function LocationFields({ itemIndex = 0, onUpdated, isEdditing }) {

  const { setSnackbarMessage } = useSnackbar();
  const {
    control,
    getValues,
    watch,
  } = useFormContext();

  const types = ["Pickup", "Delivery"];

  const [minEarlyDate] = useState(dayjs());
  const [minLateDate, setMinLateDate] = useState(dayjs());
  const [tz, setTz] = useState(null);

  useEffect(() => {
    setMinLateDate(getDayjsDate(minEarlyDate));
  }, [minEarlyDate]);

  useEffect(() => {
    setMinLateDate(
      getDayjsDate(getValues(`locations.${itemIndex}.targetEarly`))
    );
  }, [watch(`locations.${itemIndex}.targetEarly`)]);

  useEffect(() => {
    const timezone = getValues(`locations.${itemIndex}.timezone`);
    if (timezone)
      setTz(timezones.find((t) => t.name === (timezone || defaultTimezone)));
  }, [watch(`locations.${itemIndex}.timezone`)]);

  const location = watch(`locations.${itemIndex}`);
  const packages = watch(`items`);

  const validate = () => {
    const location = getValues(`locations.${itemIndex}`);

    if (!location.type) {
      showError('Type field are required.');
      return false;
    }

    return true;
  }

  const showError = (message) => {
    setSnackbarMessage({
      message: (<Box>{message}</Box>),
      severity: "error",
    });
  }

  return (
    <Stack spacing={3} width={"100%"}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography
          fontWeight={600}
          variant="h1"
          sx={{ "&.MuiTypography-h1": { fontSize: "1.25rem" } }}
        >
          {isEdditing ? 'Edit location' : 'Add location'}
        </Typography>
      </Box>

      <FormInput
        name={`locations.${itemIndex}.type`}
        label="Type"
        select
        type="text"
        fullWidth
        required
      >
        {types.map((type) => {
          return (
            <MenuItem key={type} value={type.toLocaleLowerCase()}>
              {type}
            </MenuItem>
          );
        })}
      </FormInput>
      {(location?.type ?? false) && (<LocationSearchField name={`locations.${itemIndex}.name`} index={itemIndex} />)}

      {(location?.address1 ?? false) && (
        <FormControl>
          <InputLabel id={`locations.${itemIndex}.items`}>Packages</InputLabel>
          <Controller
            control={control}
            name={`locations.${itemIndex}.items`}
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  label="Packages"
                  select
                  multiple
                  type="text"
                  value={field.value ?? []}
                  fullWidth
                  required
                >
                  {packages.map((pkg, index) => {
                    return (
                      <MenuItem key={index} value={index}>
                        {`${index + 1} - ${pkg.itemId} - ${pkg.description}`}
                      </MenuItem>
                    );
                  })}
                </Select>
              )
            }}
          />
        </FormControl>
      )}
      {(location?.items ?? false) && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              gap: 0.5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                gap: 0.5,
              }}
            >
              <Controller
                control={control}
                name={`locations.${itemIndex}.targetEarly`}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <DateTimePicker
                    {...field}
                    value={getDayjsDate(field.value)}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    timezone="UTC"
                    minDate={minEarlyDate}
                    inputRef={ref}
                    sx={{ width: "100%" }}
                    label="Target Ship Early"
                  />
                )}
              />
              <Controller
                control={control}
                name={`locations.${itemIndex}.targetLate`}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <DateTimePicker
                    {...field}
                    value={getDayjsDate(field.value)}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    timezone="UTC"
                    minDate={minLateDate}
                    inputRef={ref}
                    sx={{ width: "100%" }}
                    label="Target Ship Late"
                  />
                )}
              />
            </Box>
            {tz && (
              <Typography component="h6" sx={{ mt: 0.5 }}>
                Timezone: {tz.name} / <small>{tz.rawFormat}</small>
              </Typography>
            )}
          </Box>
          <Box>
            <Typography fontWeight={600} pb={0.5}>
              Services
            </Typography>
            <Grid container columns={6} rowSpacing={0.5}>
              {shipmentServices
                .find((servicePerMode) => servicePerMode.mode == watch("mode"))
                ?.services.map((service, index) => {
                  return (
                    <ServiceField
                      service={service}
                      key={`${service.name}${index}`}
                    />
                  );
                })}
              <Grid item xs={12}>
                <LimitedAccessField type={"location"} />
              </Grid>
            </Grid>
            {!watch("mode") && (
              <Typography
                color="GrayText"
                variant="caption"
              >Select a Shipment mode to see available services</Typography>
            )}
          </Box>

          <LocationListItem location={location} title={`${location.name} - Details`} disableActions={true} />
        </>
      )}
      <Box>
        <Button
          variant="contained"
          type="button"
          onClick={() => {
            const isValid = validate();
            if (isValid) onUpdated(itemIndex);
          }}
          fullWidth
          sx={{ minWidth: "8rem", height: "2.5rem", mb: '3rem' }}
        >
          Save
        </Button>
      </Box>
    </Stack>
  );
}
