import { MenuItem } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormInput } from "src/components/form/FormInput";
import { canadaStates } from "src/pages/warehouses/constants/canadaStates";
import { usaStates } from "src/pages/warehouses/constants/usaStates";

export const InputStateField = () => {
    const { watch, getValues, setValue } = useFormContext();

    const states = useMemo(() => {
        const country = getValues('ctry');
        if (country === 'CA') return canadaStates
        else return usaStates
    }, [watch('ctry')]);

    useEffect(() => {
        if (!states.some(state => state.acronym === getValues('state'))) setValue('state', '');
    }, [states]);

    return (
        <FormInput
            sx={{ flexGrow: 1 }}
            name="state"
            label="State"
            select
            required
        >
            {states.map((state) => {
                return (
                    <MenuItem
                        key={state.acronym}
                        value={state.acronym}
                    >
                        {state.name}
                    </MenuItem>
                );
            })}
        </FormInput>
    );
}