import config from "src/config";

const baseUrl = config.apiUrl;
const URL = `${baseUrl}/support`;

export const getIssuesType = async (token) => {
  const response = await fetch(`${URL}/issues`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.json();
};

export const contactUs = async (data, token) => {
  const response = await fetch(`${URL}/contact-us`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  return response.json();
};
