import {
  Box,
  Stack,
  MenuItem,
  Typography,
  InputLabel,
  Button,
  FormControl,
  Select,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useUserForm } from "./useUserForm";
import { Controller, FormProvider, useFieldArray } from "react-hook-form";
import { Roles, AllRoles } from "src/models/roles";
import { useEffect, useState } from "react";
import { Clear } from "@mui/icons-material";
import { useAuth } from "src/hooks/useAuth";
import { useTenant } from "src/hooks/useTenant";
import { LocationService } from "src/api/location/location.service";
import { WarehouseService } from "src/api/warehouse.service";
import api from "src/api/relationships";
import { useSnackbar } from "src/hooks/useSnackbar";

const { FormInput } = require("src/components/form/FormInput");

export function UserForm({ useFormParams, onSave, showPassword, reset }) {
  const { isLoading, form, handleSubmit, onSubmitHandler, clearErrors } =
    useUserForm({ useFormParams, onSave, reset });

  const { session } = useAuth();
  const [hidePassword, setHidePassword] = useState(showPassword);
  const [role, setRole] = useState(
    useFormParams?.defaultValues?.role?.name ?? ""
  );
  const [relationship, setRelationship] = useState([]);
  const [relationshipName, setRelationshipName] = useState("Relationships");
  const [location, setLocation] = useState([]);
  const [needsLocation, setNeedsLocation] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const isAllowedToEditRelationship = session.permissions.IsBackOffice || session.permissions.IsWarehouseManager;
  const isBackOfficeUser = session.permissions.IsBackOffice;
  const isAdminUser = session.permissions.IsAdmin;
  const [needSpecificFields, setNeedSpecificFields] = useState(false);
  const [needsTenant, setNeedsTenant] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const { tenants } = useTenant();
  const locationService = new LocationService(
    session.token,
    session.tenant._id
  );
  const warehouseService = new WarehouseService(
    session.token,
    session.tenant._id
  );

  const { setSnackbarMessage } = useSnackbar();

  const {
    fields: fieldsContact,
    remove: removeContact,
    append: appendContact,
  } = useFieldArray({ control: form.control, name: "contact" });

  useEffect(() => {
    if (useFormParams?.defaultValues) {
      mapFields(useFormParams.defaultValues);
      setRole(useFormParams.defaultValues?.role?.name ?? "User");
    }
  }, [useFormParams]);

  useEffect(() => {
    if (!isBackOfficeUser)
      return;

    setIsLoadingData(true);
    Promise.all([
      locationService.getAll().then((result) => {
        setLocation([...result]);
      }),
    ]).finally(() => setIsLoadingData(false));
  }, [session.token]);

  useEffect(() => {

    if (!role)
      return;

    try {
      setIsLoadingData(true);
      setRelationship([]);

      setRelationshipName("Loading...");
      switch (role) {
        case Roles.WarehouseManager:
        case Roles.Contractor:
          warehouseService
            .getWarehouses('disabled')
            .then((result) => {
              setRelationship(result);
              setRelationshipName("Warehouses");
            });
          setNeedSpecificFields(role == Roles.Contractor);
          break;
        // case Roles.CustomerManager:
        case Roles.SupplierManager:
          api.vendors(session.token, session.tenant._id)
            .then((result) => {
              setRelationship(result);
              setRelationshipName("Vendors");
            });
          setNeedSpecificFields(false);
          break;
        default:
          setRelationship([]);
          setRelationshipName("Relationships");
          setNeedSpecificFields(false);
          break;
      }
    } finally {
      setIsLoadingData(false);
      setNeedsLocation(updateNeedsLocation());
      setNeedsTenant(updateNeedsTenant());
      setLoaded(true);
    }
  }, [role]);

  const mapFields = (params, prefix = "") => {
    if (!params) return;

    Object.entries(params).forEach(([key, value]) => {
      if (!value) return;
      switch (key) {
        case "warehouses":
        case "vendors":
          form.setValue(prefix + key, value.map(v => v._id) ?? false);
          break;
        case "contact":
        case "tenants":
          form.setValue(prefix + key, value ?? false);
          return;
      }

      if (typeof value === "object")
        return mapFields(value, `${prefix + key}.`);

      form.setValue(prefix + key, value ?? false);
    });
  };

  const updateNeedsLocation = () =>
    role == Roles.WarehouseManager ||
    role == Roles.CustomerManager ||
    role == Roles.SupplierManager;

  const updateNeedsTenant = () =>
    role !== Roles.Administrator && role !== Roles.System;

  const isWarehouseRelationship = role ? role === Roles.WarehouseManager || role === Roles.Contractor : useFormParams?.defaultValues?.role?.name === Roles.WarehouseManager || useFormParams?.defaultValues?.role?.name === Roles.Contractor;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function Contacts() {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography component="h5" sx={{ mt: 2 }}>
          Contacts
        </Typography>
        {fieldsContact.map((contact, index) => {
          return (
            <Stack spacing={2} key={contact.id}>
              <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                <FormControl sx={{ flex: 1 }}>
                  <InputLabel id={`contact[${index}].type`}>
                    Contact Type
                  </InputLabel>
                  <Controller
                    control={form.control}
                    name={`contact[${index}].type`}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          label="Contact Type"
                          value={field.value || []}
                          MenuProps={MenuProps}
                        >
                          <MenuItem key={"email"} value={"email"}>
                            E-mail
                          </MenuItem>
                          <MenuItem key={"tel"} value={"tel"}>
                            Phone
                          </MenuItem>
                          <MenuItem key={"other"} value={"other"}>
                            Other
                          </MenuItem>
                        </Select>
                      );
                    }}
                  />
                </FormControl>
                <FormInput
                  name={`contact[${index}].value`}
                  label="Value"
                  required
                  type="text"
                  sx={{ flex: 1 }}
                />
                <Button
                  sx={{ ml: 0, mr: 0 }}
                  onClick={() => removeContact(index)}
                >
                  <Clear />
                </Button>
              </Box>
            </Stack>
          );
        })}

        <Button
          size="small"
          variant="text"
          align="left"
          onClick={() => appendContact({ name: "", phone: "", email: "" })}
        >
          + Add Contact
        </Button>
      </Box>
    );
  }

  return (
    <FormProvider {...form}>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={(event) => {
          clearErrors();
          handleSubmit(onSubmitHandler)(event);
        }}
        sx={{
          ".MuiFormHelperText-root": {
            color: "red"
          }
        }}
      >
        <Stack spacing={2}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <FormInput
              name={`firstname`}
              label="First Name"
              required
              type="text"
              sx={{ flexGrow: 1 }}
            />
            <FormInput
              name={`lastname`}
              label="Last Name"
              required
              type="text"
              sx={{ flexGrow: 1 }}
            />
          </Box>
          <FormInput
            name={`email`}
            label="Email"
            required
            type="text"
            sx={{ flexGrow: 1 }}
          />
          {hidePassword && (
            <FormInput
              name={`password`}
              label="Password"
              required
              type="password"
              sx={{ flexGrow: 1 }}
            />
          )}
          <FormInput
            name="role.name"
            label="Role name"
            defaultValue={AllRoles[0]}
            onChange={(e) => {
              form.setValue("role.warehouses", []);
              form.setValue("role.vendors", []);

              setRole(e.target.value);
            }}
            disabled={!isBackOfficeUser}
            sx={{ width: "100%" }}
            select
            required
          >
            {AllRoles.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </FormInput>
          <FormControl>
            <InputLabel id="tenants">Tenants</InputLabel>
            <Controller
              control={form.control}
              name="tenants"
              render={({ field }) => {
                return (
                  <Select
                    multiple
                    {...field}
                    label="Tenants"
                    fullWidth
                    disabled={!isAdminUser}
                    value={field.value || []}
                    MenuProps={MenuProps}
                    {...(tenants.length == 0 ||
                      (!needsTenant && { disabled: true }))}
                  >
                    {tenants.map((ten) => (
                      <MenuItem key={ten._id} value={ten._id}>
                        {ten.code} - {ten.title}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />
          </FormControl>
          <FormControl>
            <InputLabel id={isWarehouseRelationship ? 'role.warehouses' : 'role.vendors'}>{relationshipName}</InputLabel>
            <Controller
              control={form.control}
              name={isWarehouseRelationship ? 'role.warehouses' : 'role.vendors'}
              render={({ field }) => {
                return (
                  <Select
                    multiple
                    {...field}
                    label={relationshipName}
                    fullWidth
                    value={field.value || []}
                    MenuProps={MenuProps}
                    {...(!isAllowedToEditRelationship || relationship.length == 0) && { disabled: true }}>
                    {
                      relationship.map((rel) =>
                        <MenuItem key={rel._id} value={rel._id}>{rel.name}</MenuItem>
                      )
                    }
                  </Select>
                )
              }}
            />
          </FormControl>
          {needSpecificFields && (
            <Box sx={{ display: "flex", gap: 2 }}>
              <FormInput
                name={`company`}
                label="Company"
                required
                type="text"
                sx={{ flex: 1 }}
              />
              <FormInput
                name={`jobTitle`}
                label="Job Title"
                type="text"
                sx={{ flex: 1 }}
              />
            </Box>
          )}
          {isBackOfficeUser && (
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box sx={{ flex: 0.49 }}>
                <FormInput
                  {...(!needsLocation && { disabled: true })}
                  name="location"
                  label="Default Location"
                  value={useFormParams?.defaultValues?.location || null}
                  select
                  sx={{ width: "100%" }}
                >
                  {location.map((loc) => (
                    <MenuItem key={loc._id} value={loc._id}>
                      {loc.name}
                    </MenuItem>
                  ))}
                </FormInput>
              </Box>
            </Box>
          )}
        </Stack>

        {!hidePassword && (
          <Box sx={{ mt: 2 }}>
            <Button size="small" variant="text" onClick={setHidePassword}>
              + Reset password
            </Button>
          </Box>
        )}

        <Contacts />

        <LoadingButton
          variant="contained"
          fullWidth
          type="submit"
          loading={isLoading}
          sx={{ py: "0.8rem", mt: "1rem" }}
        >
          Save user
        </LoadingButton>
      </Box>
    </FormProvider>
  );
}
