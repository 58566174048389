import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Modal,
  Tab,
  Typography,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  DescriptionOutlined,
  ListAltOutlined,
  RequestQuoteOutlined,
  LocalShippingOutlined,
} from "@mui/icons-material";

import { ItemDetails } from "src/pages/dashboard/components/ItemDetails";

import { ShipmentBasicInfo } from "./BasicInfo";
import { ShipmentActions } from "./Actions";
import { QuotationsDetails } from "./Quotation";
import {
  ShipmentDetailsProvider,
  useShipmentDetails,
} from "../../context/ShipmentDetailsContext";
import { DeleteShipmentButton } from "../DeleteShipmentButton";
import { TrackingDetails } from "./TrackingDetails";
import { useAuth } from "src/hooks/useAuth";
import { LocationsDetails } from "./LocationsDetails";
import { useNavigate } from "react-router-dom";

const tabs = [
  {
    title: "Details",
    icon: <DescriptionOutlined />,
  },
  {
    title: "Pickup & Stop",
    icon: <ListAltOutlined />,
  },
  {
    title: "Packages",
    icon: <ListAltOutlined />,
  },
  {
    title: "Quotations",
    icon: <RequestQuoteOutlined />,
  },
  {
    title: "Tracking",
    icon: <LocalShippingOutlined />,
  },
];

export function ShipmentDetailsDrawer({
  title = "Testing",
  selectedItem: shipment,
  onVisibilityChanged,
  onEditTapped,
  columns,
}) {
  const { session } = useAuth();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const navigate = useNavigate();

  const [selectedTabIndex, setSelectedTabIndex] = useState("0");
  const onTabSelected = (event, tabIndex) => {
    setSelectedTabIndex(tabIndex);
  };

  const toggleDrawer = (isVisible) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsDrawerOpen(isVisible);
    onVisibilityChanged(isVisible);

    navigate('/shipments');
  };

  useEffect(() => {
    setIsDrawerOpen(!!shipment);
  }, [shipment]);

  const IsBackOffice = session.permissions.IsBackOffice;

  const Comp = true ? Drawer : Modal;

  return (
    <ShipmentDetailsProvider shipment={shipment}>
      <Comp
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{ sx: { width: '50vw' } }}
        sx={{
          backgroundColor: theme => theme.palette.primary,
          maxWidth: '80vw',
        }}
      >
        <Box
          role="presentation"
          sx={{
            py: "1.5rem",
          }}
        >
          {/* DRAWER HEADER */}
          <DrawerHeader
            onEditTapped={() => onEditTapped(shipment)}
            title={title}
            closeDrawer={() => toggleDrawer(false)}
            session={session}
            shipment={shipment}
            HeaderActions={<ShipmentActions />}
          />
          <Divider />
          <TabContext value={selectedTabIndex}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                width: "100%",
              }}
            >
              <ShipmentDrawerTabs onChange={onTabSelected} />
            </Box>
            <TabPanel
              value={"0"}
              sx={{
                maxWidth: "880px",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                "&.MuiTabPanel-root": { padding: 0 },
              }}
            >
              <ShipmentBasicInfo
                shipment={shipment}
                setSelectedTabIndex={setSelectedTabIndex}
              />
              <Box>
                <DeleteShipmentButton allowDeletion={IsBackOffice} />
              </Box>
            </TabPanel>

            <TabPanel value={"1"} >
              <LocationsDetails shipment={shipment}/>
            </TabPanel>

            <TabPanel
              value={"2"}
              sx={{ maxWidth: "880px", "&.MuiTabPanel-root": { padding: 3 } }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  gap: "0.75rem",
                }}
              >
                <Card
                  variant="outlined"
                  sx={{
                    py: "0.5rem",
                    px: "1rem",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.75rem",
                  }}
                >
                  <CardHeader
                    component={() => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          py: "0.25rem",
                          pr: "0.5rem",
                        }}
                      >
                        <Typography
                          variant="h1"
                          sx={{ "&.MuiTypography-h1": { fontSize: "1.25rem" } }}
                        >
                          Packages
                        </Typography>
                        <Typography color={"GrayText"}>
                          {valueGetter(
                            shipment.locations[0].items?.length,
                            "No "
                          )}{" "}
                          Package(s)
                        </Typography>
                      </Box>
                    )}
                    sx={{
                      "&.MuiCardHeader-root": {
                        p: 0,
                      },
                    }}
                  />
                  {!!shipment.locations[0].items?.length && <Divider />}
                  <CardContent
                    sx={{
                      "&.MuiCardContent-root": {
                        p: 0,
                      },
                    }}
                  >
                    {shipment.locations[0].items?.map((item) => (
                      <ItemDetails item={item} key={item._id} expanded />
                    ))}
                  </CardContent>
                </Card>
              </Box>
            </TabPanel>

            <TabPanel value={"3"} >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.75rem",
                  "&.MuiTabPanel-root": { padding: 0 },
                }}
              >
                <QuotationsDetails />
              </Box>
            </TabPanel>
            <TabPanel
              value={"4"}
              sx={{
                "&.MuiTabPanel-root": { padding: 0 },
              }}
            >
              <TrackingDetails />
            </TabPanel>
          </TabContext>
        </Box>
      </Comp>
    </ShipmentDetailsProvider>
  );
}

function DrawerHeader({ onEditTapped, title, closeDrawer, HeaderActions }) {
  return (
    <Box
      display={'grid'}
      gridTemplateAreas={{
        xs: `
          "title title close"
          "actions actions actions"
        `,
        md: `title actions close`
      }}
      gridAutoRows={{
        xs: 'auto auto',
        md: 'auto'
      }}
      alignItems="center"
      spacing={2}
      sx={{
        px: "1rem",
        mb: "1rem",
      }}
    >
      <Box gridArea={'title'} display={'flex'} alignItems={'center'}>
        <IconButton onClick={() => onEditTapped()} sx={{ p: 2 }}>
          <EditIcon />
        </IconButton>
        <Typography variant="h6">{title}</Typography>
      </Box>

      <Box display="flex" alignItems="flex" gridArea={'actions'}>
        {HeaderActions}
      </Box>
      <Box justifySelf={'end'} gridArea={'close'}>
        <IconButton onClick={closeDrawer()} >
          <ChevronRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

function ShipmentDrawerTabs({ onChange }) {
  const { tracking, quotations } = useShipmentDetails();
  return (
    <TabList
      onChange={onChange} aria-label="Shipment details"
      variant="scrollable"
      scrollButtons={true}
      allowScrollButtonsMobile
    >
      {tabs.map((item, index) => {
        if (item.title == "Quotations" && !quotations.length) return;
        if (item.title == "Tracking" && !tracking.length) return;
        return (
          <Tab
            icon={item.icon}
            iconPosition="start"
            label={item.title}
            value={index.toString()}
            key={item.title}
          />
        );
      })}
    </TabList>
  );
}

export function valueGetter(value, caseEmptyValue = "--") {
  return value ? value : caseEmptyValue;
}
