import { Box, Typography } from "@mui/material";
import { valueGetter } from "src/components/drawer/DetailsDrawer";

export function HazardousDetails({ hazardous }) {
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    py: "0.25rem",
                }}
            >
                <Typography>Shipping name</Typography>
                <Typography fontWeight={600}>
                    {valueGetter(hazardous.properShippingName || "N/A")}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    py: "0.25rem",
                }}
            >
                <Typography>Contact name</Typography>
                <Typography fontWeight={600}>
                    {valueGetter(hazardous.contactName || "N/A")}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    py: "0.25rem",
                }}
            >
                <Typography>Contact phone</Typography>
                <Typography fontWeight={600}>
                    {valueGetter(hazardous.contactPhone || "N/A")}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    py: "0.25rem",
                }}
            >
                <Typography>Group</Typography>
                <Typography fontWeight={600}>
                    {valueGetter(hazardous.group || "N/A")}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    py: "0.25rem",
                }}
            >
                <Typography>Flashpoint temp</Typography>
                <Typography fontWeight={600}>
                    {valueGetter(hazardous.flashpointTemp || "N/A")}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    py: "0.25rem",
                }}
            >
                <Typography>Un Number</Typography>
                <Typography fontWeight={600}>
                    {valueGetter(hazardous.unNumber || "N/A")}
                </Typography>
            </Box>
        </>
    );
}