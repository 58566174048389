import config from "src/config";
import {
    BaseService
} from "./base.service";

const baseUrl = config.apiUrl;
const prefix = "/vendors";

export class VendorService extends BaseService {

    getAll = async () => {
        const uri = new URL(`${baseUrl}/relationships${prefix}`);

        const response = await fetch(uri.toString(), this.header());
        const data = response.json()

        if (response.ok)
            return data

        if (response.status === 401)
            throw new Error(data?.message || response.statusText);
    }
}