import { Link } from "@mui/material";
import { Auth } from "src/models/roles";
import { ItemDetails } from "./components/ItemDetails";
import { fDateString } from "src/utils/formatTime";

export const shipmentDetailColumns = [
  {
    key: "poNumber",
    label: "PO Number",
  },
  {
    key: "primaryReference",
    label: "Primary Reference",
  },
  {
    key: "mode",
    label: "Mode",
    renderValue: (value) => value.mode?.toUpperCase(),
  },
  {
    key: "status",
    label: "Status",
  },
  {
    key: "quantity",
    label: "Pallets",
  },
  {
    key: "weight",
    label: "Weight",
  },
  {
    key: "shipper",
    label: "Shipper",
    renderValue: (value) => value.locations[0]?.name,
  },
  {
    key: "originLocation",
    label: "Origin",
    renderValue: (value) => value.locations[0]?.address1,
  },
  {
    key: "originTargetShipEarly",
    label: "Target ship early",
    renderValue: (value) => fDateString(value.locations[0]?.targetEarly),
  },
  {
    key: "originTargetShipLate",
    label: "Target ship early",
    renderValue: (value) => fDateString(value.locations[0]?.targetLate),
  },
  {
    key: "receiver",
    label: "Receiver",
    renderValue: (value) => value.locations[1]?.name,
  },
  {
    key: "destinationLocation",
    label: "Destination",
    renderValue: (value) => value.locations[1]?.address1,
  },
  {
    key: "destTargetDeliveryEarly",
    label: "Target delivery early",
    renderValue: (value) => fDateString(value.locations[1]?.targetEarly),
  },
  {
    key: "destTargetDeliveryLate",
    label: "Target delivery late",
    renderValue: (value) => fDateString(value.locations[1]?.targetLate),
  },
  {
    field: 'items',
    label: 'Items',
    renderValue: (value) => {
      const items = value?.locations[0]?.items || null 
      return items.map(item => <ItemDetails item={item} />)
    },
  },
  {
    key: "trackingUrl",
    label: "Tracking URL",
    renderValue: (value, user) => value.trackingUrl ? <Link href={value.trackingUrl}>{value.trackingUrl}</Link> : 'Unavaliable'
  },
  {
    key: "bolFile.url",
    label: "BOL",
    renderValue: (value, user) => {
      if (value.bolFile) return <Link href={value.bolFile?.url}>Download BOL</Link>;
      if (Auth(user).IsBackOffice) {
        return <Link href={`shipments/${value._id}/bol`}>Upload BOL</Link>;
      }

      return <></>;
    },
  },
  {
    key: "podFile.url",
    label: "POD",
    renderValue: (value, user) => {
      if (value.podFile) return <Link href={value.podFile?.url}>Download POD</Link>;
      if (Auth(user).IsBackOffice) {
        return <Link href={`shipments/${value._id}/pod`}>Upload POD</Link>;
      }

      return <></>;
    },
  },
];