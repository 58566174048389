import {
  ControlPointDuplicate,
  LocalShippingOutlined,
  RequestQuoteOutlined,
  TaskAltOutlined,
  RestartAlt,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, Tooltip } from "@mui/material";
import { useShipmentDetails } from "../../context/ShipmentDetailsContext";
import { useAuth } from "src/hooks/useAuth";

export function useActionables() {
  const {
    shipment,
    quotations,
    confirmShipment,
    getCarrierQuotations,
    isConfirmationLoading,
    isQuotationLoading,
    isBackofficeUser,
    resetShipmentStatus,
    isResettingShipmentLoading
  } = useShipmentDetails();

  const isQuotationFeed = !!quotations.length;
  const needsValidation =
    !!(shipment?.poNumber?.length > 0) &&
    !shipment.validated &&
    ((shipment.mode == "TL" &&
      !shipment.integrations?.find(
        (integration) => integration?.target == "trinity"
      )?.status == "success") ||
      (shipment.mode == "LTL" &&
        false && // TODO Remove when migrate validation to booking method.
        shipment.integrations?.find(
          (integration) => integration?.target == "banyan"
        )?.status == "success"));

  const actionables = [
    {
      key: "confirm-shipment",
      isShown:
        isBackofficeUser &&
        !needsValidation &&
        shipment.mode?.toLocaleUpperCase() == "TL",
      Actionable: () => (
        <>
          <LoadingButton
            loading={isConfirmationLoading}
            loadingPosition="start"
            startIcon={
              shipment.integrated ? (
                <TaskAltOutlined />
              ) : (
                <LocalShippingOutlined />
              )
            }
            onClick={confirmShipment}
            disabled={shipment.integrated}
            variant="outlined"
            color={shipment.integrated ? "success" : "primary"}
            sx={{
              "&.Mui-disabled": {
                color: (theme) => theme.palette.success.darker,
                border: (theme) => theme.palette.success.darker,
                opacity: 0.8,
              },
            }}
          >
            {shipment.integrated ? "Confirmed" : "Confirm Shipment"}
          </LoadingButton>
        </>
      ),
    },
    {
      key: "reconfirm-shipment",
      isShown: isBackofficeUser && shipment.integrated,
      Actionable: () => (
        <Tooltip title="Update Confirmation">
          <LoadingButton
            loading={isConfirmationLoading}
            loadingPosition="start"
            startIcon={<LocalShippingOutlined width={"2rem"} />}
            onClick={confirmShipment}
            variant="outlined"
            color={"primary"}
          >
            Reconfirm
          </LoadingButton>
        </Tooltip>
      ),
    },
    {
      key: "request-quotation",
      isShown:
        isBackofficeUser &&
        !needsValidation &&
        shipment.mode == "LTL" &&
        !isQuotationFeed,
      Actionable: () => (
        <LoadingButton
          loading={isQuotationLoading}
          loadingPosition="start"
          startIcon={<RequestQuoteOutlined />}
          onClick={getCarrierQuotations}
          disabled={
            isQuotationFeed ||
            shipment.intregations?.some(
              (integration) =>
                integration.target == "banyan" && integration == "success"
            )
          }
          variant="outlined"
          color={isQuotationFeed ? "success" : "primary"}
          sx={{
            "&.Mui-disabled": {
              color: (theme) => theme.palette.success.darker,
              border: (theme) => theme.palette.success.darker,
              opacity: 0.8,
            },
          }}
        >
          {isQuotationLoading ? "Loading Quotation" : "Request Quotation"}
        </LoadingButton>
      ),
    },
    {
      key: "duplicate-shipment",
      isShown: true,
      Actionable: () => (
        <Tooltip title='Duplicate Shipment' >
          <Button  
            href={`/shipments/${shipment._id}/edit?duplicate`} 
            sx={{
              display: 'flex',
              gap: '0.5rem',
            }}
            variant="outlined"
          >
            <ControlPointDuplicate/>
            Duplicate
          </Button>
        </Tooltip>
      ),
    },
    {
      key: "reset-shipment-status",
      isShown: 
      isBackofficeUser &&
        shipment.mode === 'LTL' &&
        shipment.integrated &&
        shipment.integrations.some(integration => integration.target.toLowerCase() === 'banyan')
      ,
      Actionable: () => (
        <Tooltip title='Reset Shipment Status' >
          <LoadingButton  
           onClick={resetShipmentStatus}
           loading={isResettingShipmentLoading}
            sx={{
              display: 'flex',
              gap: '0.5rem',
            }}
            variant="outlined"
          >
            <RestartAlt/>
            Reset Shipment Status
          </LoadingButton>
        </Tooltip>
      ),
    }
  ];

  return actionables;
}
