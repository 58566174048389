import { BaseService } from "./base.service";
import config from "src/config";

export class StorageService extends BaseService {
  baseUrl = `${config.apiUrl}/support`;

  async getFile(filename, container) {
    const uri = new URL(`${this.baseUrl}/download`);
    if (container) uri.searchParams.append("container", container);

    uri.searchParams.append("file", filename);
    const response = await fetch(uri.toString(), this.header());

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      return {
        url,
        filename,
        blob,
        download: () => this.downloadFile(filename, url),
        openFile: () => this.openFile(url),
      };
    } else throw new Error("Error to get file");
  }

  async downloadFile(filename, url) {
    const a = document.createElement("a");
    a.href = url;
    a.download = filename; // Set the filename
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  async openFile(url) {
    window.open(url, "_blank");
  }

  listBlobs = async (container) => {
    const uri = new URL(`${this.baseUrl}/list-blobs`);
    if (container) uri.searchParams.append("container", container);

    const response = await fetch(uri.toString(), this.header());
    return await response.json();
  };
}
