import { CalendarMonth, LocationOn, QuestionMarkOutlined } from "@mui/icons-material";
import HouseIcom from "@mui/icons-material/House";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import PeopleIcon from "@mui/icons-material/People";
import AssignmentIcon from '@mui/icons-material/Assignment';

const navConfig = [
  {
    title: "Dashboard",
    path: "/shipments",
    icon: <LocalShippingOutlinedIcon />,
    showWhen: (session) => {
      const authorizedRoles = [
        "IsAdmin",
        "IsBackOffice",
        "IsWarehouseManager",
        "IsSupplierManager",
        "IsCustomerManager",
      ];

      if(session.tenant.modules.shipments) {
        return authorizedRoles.some((role) => session.permissions[role]);
      }

      return false
    },
  },
  {
    title: "Warehouses",
    path: "/warehouses",
    icon: <HouseIcom />,
    showWhen: (session) => {
      const authorizedRoles = [ "IsBackOffice", "IsWarehouseManager" ]

      if(session.tenant.modules.warehouse) {
        return authorizedRoles.some((role) => session.permissions[role]);
      }
      return false;
    },
  },
  {
    title: "Locations",
    path: "/locations",
    icon: <LocationOn />,
    showWhen: (session) => {
      const authorizedRoles = [ "IsBackOffice"];

      return authorizedRoles.some((role) => session.permissions[role]);
    }
  },
  {
    title: "Orders",
    path: "/orders",
    icon: <AssignmentIcon />,
    showWhen: (session) => {
      const authorizedRoles = [ "IsBackOffice"];

      return authorizedRoles.some((role) => session.permissions[role]);
    }
  },
  {
    title: "Schedule",
    path: "/schedule/by-date",
    icon: <CalendarMonth />,
    showWhen: (session) => {
      const authorizedRoles = [ 'IsBackOffice', 'IsWarehouseManager', 'IsContractor' ];
      
      if(session.tenant.modules.schedule) {
        return authorizedRoles.some((role) => session.permissions[role]);
      }

      return false;
    }
  },
  {
    title: "Users",
    path: "/users",
    icon: <PeopleIcon />,
  },
  {
    title: "Help Center",
    path: "/support",
    icon: <QuestionMarkOutlined />,
  },
];

export default navConfig;
